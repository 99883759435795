// import React, { useState, useEffect } from 'react';
// import './Slider.css';

// const Slideshow = () => {
//     // Define an array of image URLs
//     const images = [
//         "https://ideogram.ai/api/images/direct/vHybCKPISV2MNlq2nK96xg.png",
//         " https://ideogram.ai/api/images/direct/gGZNNh28T7C98-vgsx2fmg.png",
//         " https://ideogram.ai/api/images/direct/QTb5y-mlQ5yPhlrHTbS38A.png"
//         // "slide1.png",
//         // "slide2.jpg",
//         // "slide3.jpg"
//     ];

//     // State to keep track of the index of the current image
//     const [currentIndex, setCurrentIndex] = useState(0);

//     // Function to move to the next image
//     const nextSlide = () => {
//         setCurrentIndex((prevIndex) =>
//             prevIndex === images.length - 1 ? 0 : prevIndex + 1
//         );
//     };

//     useEffect(() => {
//         // Set an interval to automatically move to the next image every 3 seconds
//         const interval = setInterval(nextSlide, 2000);

//         // Clear the interval on component unmount to prevent memory leaks
//         return () => clearInterval(interval);
//     }, []);

//     return (
//         <div className='slider'>
//         <div className="slideshow">
//             <div className='img-slider'>
//                 <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className="slide-image" />
//             </div>
//         </div>
//         </div>
//     );
// };

// export default Slideshow;


import React from "react";
import './Slider.css'
import slide1 from '../assects/slide1.jpg'
import slide2 from '../assects/1.jpg'

import store from '../assects/7.jpg';

const Slideshow = () => {
    return (
        <div className="slider">
            {/* <div className="slidershow">
                <div className="img-slider">
                    <img src={slide2} alt=""></img>
                </div>
            </div> */}

<div className="pro-discription">
    <img  src= {store}  alt=""/>
</div>

        </div>
    )
};

export default Slideshow;