import React from "react";
import StarRating from "./startRating";
import './RetailProducts.css'
import ashbottle from '../assects/ash-bottle.png'
import honey from '../assects/honey.png'
import honey2 from '../assects/honey2.png'
// import ashslide from '../assects/ash-slide.png'
import allproimg from '../assects/6.jpg'
import Jaggery from '../assects/Jaggery.png'
import Multiflora from '../assects/Multiflora.png'
import Babool from '../assects/Babool.png'
import HerbalTea from '../assects/HERBAL-TEA.png'



const RetailProducts = () => {
    return (
        <div>
            <div className="pro-slider">
                <div>
                    <div className="product-img-slider">
                        <img src={allproimg} alt="" />
                        <img src="" alt="" />
                        <img src="" alt="" />
                        <img src="" alt="" />
                    </div>
                </div>
            </div>


            <div className="all-pro">
                <h2 className="sub-title">Wellness Products</h2>
                <div className="boxes">
                    <div className="sub-box">
                        <img src={ashbottle} alt="ashwagandha" />
                        <button><a>Ashwagandha</a></button>
                    </div>

                    <div className="sub-box">
                        <img src={Jaggery} alt="" />
                        <button><a>Jaggery</a></button>
                    </div>
                </div>
            </div>


            <div className="all-pro">
                <h2 className="sub-title">Honey</h2>
                <div className="boxes">
                    <div className="sub-box">
                        <img src={Babool} alt="honey" />
                        <button><a>Ashwagandha</a></button>
                    </div>

                    <div className="sub-box">
                        <img src={Multiflora} alt="" />
                        <button><a>Jaggery</a></button>
                    </div>
                </div>
            </div>

            <div className="all-pro">
                <h2 className="sub-title">Tea</h2>
                <div className="boxes">
                <div className="sub-box">
                        <img src={HerbalTea} alt="Hearbal Tea" />
                        <button><a>Hearbal Tea</a></button>
                    </div>
                </div>
            </div>

            <div className="reviews">
                <div>
                    <h1>Reviews</h1>
                    <div className="review-box">
                        <div className="rev">
                            <h2>Atharv04</h2>
                            <StarRating count={4} size="1x" color="#ffc107" />
                            <p>"I've been using Forest Wellness Ashwagandha for a month now, and the results are fantastic. I feel more relaxed and my stress levels have significantly decreased. The quality is outstanding, and I love that it's sourced from natural, sustainable forests."</p>
                        </div>

                        <div className="rev">
                            <h2>Tanish00</h2>
                            <StarRating count={3} size="1x" color="#ffc107" />
                            <p>"I recently switched to Forest Wellness Stevia, and it’s been a game-changer. It’s a natural, zero-calorie sweetener that doesn’t compromise on taste. Perfect for my coffee and desserts, and it feels good to use a product that’s sustainably harvested from nature."</p>
                        </div>

                        <div className="rev">
                            <h2>Ravi590</h2>
                            <StarRating count={5} size="1x" color="#ffc107" />
                            <p>"Forest Wellness Honey is simply the best. It’s pure, organic, and has a rich, delicious flavor. I use it every morning in my tea, and it’s also great for baking. Knowing it comes from pristine forest areas makes it even better."</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetailProducts;

