import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ count, size, color }) => {
  return (
    <div>
      {[...Array(count)].map((star, index) => {
        return (
          <FontAwesomeIcon
            key={index}
            icon={faStar}
            size={size}
            color={color}
          />
        );
      })}
    </div>
  );
};

StarRating.defaultProps = {
  count: 5,
  size: '1x',
  color: '#ffc107'
};

export default StarRating;
