import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components /Navbar';
import Home from './components /Home';
import RetailProducts from './components /RetailProducts';
import BukProducts from './components /BulkProducts'
import Contact from './components /Contact';

// import Categories from './components /Catogires'
// import Header from './components /Header'
// import Slider from './components /Slider'
// import Aboutus from './components /Aboutus'
// import Committed from './components /Committed'


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Retail-products" element={<RetailProducts />} />
          <Route path="/BulkProducts" element={<BukProducts />} />
        </Routes>

        {/* <Helmet>

          <title>Forest Wellness | Organic Products</title>
          <meta name="description" content="Discover organic products like honey, herbal tea, and more at Forest Wellness. Quality and sustainability in every product." />
          <link rel="canonical" href="https://www.forestwellness.com" />

        </Helmet>
        <Categories />

        <Header />

        <Slider />

        <Committed />

        <Aboutus /> */}

        <Contact />

      </div>
    </Router>
  );
}

export default App;


