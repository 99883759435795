import React from "react";
// import Apowder2 from '../assects/ashwagandha powder2.jpeg'
// import Apowder from '../assects/A powder.jpeg'
import Apowder from '../assects/Apowder4.jpeg'
import Moringa from '../assects/M powder.jpeg'
// import Stevia from '../assects/S powder.jpeg'
import Stevia from '../assects/Stevia powder4.jpeg'
import './Catogires.css'

const Categories = () => {
    return (
        <div>
            <div className="small-slider">
                <div className="small-container">
                    <div className="small-img">
                        <img src={Apowder} alt="ashwagandha powder"></img>
                        <div className="overlay-cat"><p>Ashwagandha</p></div>
                        </div>
                    <div className="small-img">
                        <img src={Moringa} alt="moringa powder"></img>
                        <div className="overlay-cat"><p>Moringa</p></div>
                        </div>
                    <div className="small-img">
                        <img src={Stevia} alt="stevia powder"></img>
                        <div className="overlay-cat"><p>Stevia</p></div>
                        </div>
                </div>
            </div>
        </div>
    )
};

export default Categories;