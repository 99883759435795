import React from 'react';
import { Helmet } from 'react-helmet';

import Categories from './Catogires'
import Header from './Header'
import Slider from './Slider'
import Aboutus from './Aboutus'
import Committed from './Committed'


function Home() {
    console.log('Home component loaded'); 
    return (
        <div>

            <Helmet>

                <title>Forest Wellness | Organic Products</title>
                <meta name="description" content="Discover organic products like honey, herbal tea, and more at Forest Wellness. Quality and sustainability in every product." />
                <link rel="canonical" href="https://www.forestwellness.com" />

            </Helmet>
            <Categories />

            <Header />

            <Slider />

            <Committed />

            <Aboutus />

        </div>

    );
}

export default Home;
