// import React, { useEffect, useState } from "react";
// import "./Navbar.css"
// import { Link } from 'react-router-dom';
// import Logo from '../assects/Logo_forest.PNG';

// const Navbar = () => {
//     const [shrink, setShrink] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 50) {
//                 setShrink(true);
//             } else {
//                 setShrink(false);
//             }
//         };

//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);
//     const [isHovered, setIsHovered] = useState(false);

//     const [isOpen, setIsOpen] = useState(false);

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };



//     return (
//         <div className="nav">
//             <nav className={shrink ? 'navbar shrink' : 'navbar'}>

//                 <div className="navbar">

//                     <ul className="nav-items">

//                         <div className="nav-left"><li className="nav-list"><Link to="/Home">Home</Link></li>
//                             <li className="nav-list">
//                                 <div className="drop-down"
//                                     onMouseEnter={() => setIsHovered(true)}
//                                     onMouseLeave={() => setIsHovered(false)}>
//                                     <div className="hover-element">
//                                         <li>
//                                             <Link to="/Retail-products">Retail-Products</Link>
//                                         </li>
//                                     </div>
//                                     <div className="dropdown-content">
//                                         <a href='#'>Ashwagandha</a>
//                                         <a href='#'>Honey</a>
//                                         <a href='#'>Moringa</a></div>
//                                 </div></li>
//                             <li className="nav-list">
//                                 <Link to="/BulkProducts">Bulk Products</Link></li>
//                             <div className="top-logo">
//                                 <img src={Logo} alt="" className="toplogo" />
//                             </div>
//                         </div>
//                         <div className="nav-right">
//                             <li className="nav-list"><a href="#aboutus">About Us</a></li>
//                             <li className="nav-list"><a href="#contactus">Contact Us</a></li>
//                             <li className="nav-list"><a href="https://www.amazon.in/?&tag=googhydrabk1-21&ref=pd_sl_7hz2t19t5c_e&adgrpid=155259815513&hvpone=&hvptwo=&hvadid=674842289437&hvpos=&hvnetw=g&hvrand=10994797013207820378&hvqmt=e&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9299777&hvtargid=kwd-10573980&hydadcr=14453_2316415&gad_source=1">Store</a></li>
//                         </div>
//                     </ul>
//                 </div>

//                 <div className="mini-nav">
//                     <img src={Logo} alt="" />
//                 </div>


//             </nav>
//         </div>
//     );
// }

// export default Navbar;

import React, { useEffect, useState } from "react";
import "./Navbar.css"
import { Link } from 'react-router-dom';
import Logo from '../assects/Logo_forest.PNG';

const Navbar = () => {
    const [shrink, setShrink] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShrink(true);
            } else {
                setShrink(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu state
    };

    return (
        <div className="nav">
            <nav className={shrink ? 'navbar shrink' : 'navbar'}>
                <div className="navbar">
                    <ul className="nav-items">
                        <div className="nav-left">
                            <li className="nav-list"><Link to="/">Home</Link></li>
                            <li className="nav-list">
                                <div className="drop-down"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <div className="hover-element">
                                        <li><Link to="/Retail-products">Retail Products</Link></li>
                                    </div>
                                    <div className="dropdown-content">
                                        <a href='#'>Ashwagandha</a>
                                        <a href='#'>Honey</a>
                                        <a href='#'>Moringa</a>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-list"><Link to="/BulkProducts">Bulk Products</Link></li>
                        </div>
                        <div className="top-logo">
                            <img src={Logo} alt="" className="toplogo" />
                        </div>
                        <div className="nav-right">
                            <li className="nav-list"><a href="#aboutus">About Us</a></li>
                            <li className="nav-list"><a href="#contactus">Contact Us</a></li>
                            <li className="nav-list"><a href="https://www.amazon.in/?&tag=googhydrabk1-21&ref=pd_sl_7hz2t19t5c_e&adgrpid=155259815513&hvpone=&hvptwo=&hvadid=674842289437&hvpos=&hvnetw=g&hvrand=10994797013207820378&hvqmt=e&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9299777&hvtargid=kwd-10573980&hydadcr=14453_2316415&gad_source=1">Store</a></li>
                        </div>
                    </ul>
                </div>

                {/* Mobile Menu Icon */}
                <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                    <div className={isMobileMenuOpen ? "line open" : "line"}></div>
                    <div className={isMobileMenuOpen ? "line open" : "line"}></div>
                    <div className={isMobileMenuOpen ? "line open" : "line"}></div>
                </div>

                {/* Mobile Menu */}
                <div className={isMobileMenuOpen ? "mobile-nav open" : "mobile-nav"}>
                    <ul>
                        <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
                        <li><Link to="/Retail-products" onClick={toggleMobileMenu}>Retail Products</Link></li>
                        <li><Link to="/BulkProducts" onClick={toggleMobileMenu}>Bulk Products</Link></li>
                        <li><a href="#aboutus" onClick={toggleMobileMenu}>About Us</a></li>
                        <li><a href="#contactus" onClick={toggleMobileMenu}>Contact Us</a></li>
                        <li><a href="https://www.amazon.in/?&tag=googhydrabk1-21&ref=pd_sl_7hz2t19t5c_e&adgrpid=155259815513&hvpone=&hvptwo=&hvadid=674842289437&hvpos=&hvnetw=g&hvrand=10994797013207820378&hvqmt=e&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9299777&hvtargid=kwd-10573980&hydadcr=14453_2316415&gad_source=1" onClick={toggleMobileMenu}>Store</a></li>
                    </ul>
                </div>

                <div className="mobile-logo">
                    <img src={Logo} alt="forest wellness logo"  />
                </div>

            </nav>
        </div>
    );
}

export default Navbar;
