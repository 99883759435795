import React from "react";
import './Committed.css';

const commitedTo = () => {
    return (
        <div id="services">
            <div className="committed">
                <div className="committed-h1">
                    <h1>Forest Wellness Is Committed To</h1>
                </div>
                <div className="containers">
                    <div className="box"><h3>Absolute Qulality</h3>
                        <div><p>Exquisite Natural Integrity, and Unparalleled Excellence are the cornerstones of our
                            commitment at Nature's Essence Organic Products. With a deep-rooted passion for health and
                            sustainability, we meticulously source and curate the finest organic
                            products and herbs, ensuring that each item reflects the purity and potency of nature's bounty.</p></div></div>
                            <div className="box"><h3>Total Integrity</h3>
                        <div><p>We embody 'Total Integrity' in every aspect of our business. Our commitment to integrity-driven service is evident
                             in our core values of honesty, transparency, and ethical practices.
                            Honesty is the foundation of our interactions with customers, suppliers, and stakeholders. We believe in being upfront 
                            and truthful in all our dealings, from the sourcing of organic products and herbs to the information provided about our offerings.</p></div>
                            </div>
                    {/* <div className="box"><h3>100% Ayurvedic</h3>
                        <div><p>we pride ourselves on offering 100% Ayurvedic products crafted with Pristine Ayurvedic purity and authentically
                             holistic principles.Our commitment to Ayurveda runs deep, guiding every aspect of our sourcing, production, and 
                             distribution processes. We meticulously select and procure organic herbs and ingredients from trusted sources, 
                             ensuring their purity and potency are preserved from farm to bottle.</p></div>
                             </div> */}
                    <div className="box"><h3>Services To All</h3>
                        <div><p>Our ethos revolves around 'Services To All,' where we strive to deliver the best in every aspect of our operations. 
                            Our commitment is rooted in providing unmatched quality, reliability, and customer satisfaction to all our patrons.
                            We meticulously source the finest organic products and herbs, ensuring that each item upholds the highest standards 
                            of purity and potency.</p></div></div>
                    
                </div>
            </div>
        </div>
    );
};

export default commitedTo;