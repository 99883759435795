import React from "react";
import aboutus from '../assects/aboutus.jpg'
import objective from '../assects/5.jpg'
// import world from './world-environment-earth-day-concept-generative-ai(1).jpg'

import './Aboutus.css';

const Aboutus = () => {
    return (
        <div id="aboutus">
            <div className="aboutus">
                <h1 className="abouthead">ABOUT US</h1>
                <div className="about-image">
                    <img src={aboutus} alt="About Company" />
                    <div className="about-text">
                        <p>Forest Wellness is a health and wellness brand dedicated to offering a natural path to well-being
                            through its range of high-quality, organic products. Specializing in the cultivation and sale of
                            powerful superfoods like Ashwagandha, Moringa, and Stevia, Forest Wellness harnesses the purity and
                            potency of nature to enhance physical, mental, and emotional health. Committed to sustainability and
                            ethical sourcing, each product is carefully selected and processed to retain its nutritional benefits,
                            ensuring customers receive the finest nature has to offer. Ideal for health-conscious individuals seeking
                            organic alternatives to supplement their diet, Forest Wellness stands at the forefront of natural wellness,
                            providing solutions that nurture the body, mind, and spirit.</p>
                    </div>
                </div>
            </div>
            <div className="objective">
                <div className="our-objective">
                <img  src={objective} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Aboutus;