import React from "react";
import './Header.css'
// import animatedlogo from './logodesign2.png'
// import filogo from '../assects/fina logo.jpeg'
// import index from '../assects/indexlogo.jpeg'
import logo from '../assects/Logo_forest.PNG'

const Header = () => {
    return (
        <div className="homepg">
            <div className="head">
                <div className="head-top">
                </div>
                <h1>Forest
                    Wellness
                </h1>
                <br />
                <div className="slogan">
                <h2>Harnessing The Power Of Nature</h2>
                </div>
            </div>
            <div className="animate-logo">
                <img src={logo} alt=" animated logo" />
            </div>
        </div>
    );
};

export default Header;